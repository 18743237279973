function init () {
  console.log('init')

  // Setup the DOM elements
  const menuBtn = document.getElementById('menu-btn')
  const sheet = document.getElementById('sheet')
  let sheetUp = true
  console.log(sheetUp)

  // Setup the eventListeners
  menuBtn.addEventListener('click', () => animateSheet())

  function animateSheet () {
    sheetUp ? revealNav() : hideNav()
  }

  // Animation functions
  function revealNav () {
    sheet.classList.remove('sheet-up')
    sheet.classList.add('sheet-down')
    sheetUp = false
  }

  function hideNav () {
    sheet.classList.remove('sheet-down')
    sheet.classList.add('sheet-up')
    sheetUp = true
  }
}

init()